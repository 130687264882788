import { useState, useEffect, useCallback } from "react";
import { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import FormData from "form-data";
import _ from "lodash";
import { toast } from "react-toastify";
// Components
import { Layout } from "../components/templates";
import { SliderHome, CategoryHome, ProductHome, ShopHome } from "../components/organisms";
// Configs
import { APP_BASEURL } from "../configs";
import { Locations, AssetsUri } from "../configs/datatype";
// Utils
import { convertToDistance, encoded } from "../utils";
// Data
import { serviceGetIdAddressForProduct, serviceGetProductsByLocation } from "../services";

const Home: NextPage = () => {
  const [products, setProducts] = useState<Array<object>>([]);
  const [shops, setShops] = useState<Array<object>>([]);
  const [categories, setCategories] = useState<Array<object>>([]);
  const [lastorders, setLastOrders] = useState<Array<object>>([]);
  const [asseturi, setAssetUri] = useState<Partial<AssetsUri>>({});
  const [location, setLocation] = useState<Partial<Locations>>({});
  const [loading, setLoading] = useState<boolean>(true);

  const router = useRouter();

  const handleSetLocation = (value: Locations) => {
    setLocation(value);
  };

  const handleAddDistance = (latitude: string, longitude: string) => {
    const nearbyInMeters = convertToDistance(location?.coordinate, { latitude, longitude });

    const nearbyInKilometers = nearbyInMeters / 1000;

    return nearbyInKilometers.toFixed(1);
  };

  const getAddressAndProducts = useCallback(async () => {
    setLoading(true);

    try {
      const fdaddress = new FormData();
      fdaddress.append("google[kelurahan][name]", location?.kelurahan?.toLowerCase());
      fdaddress.append("google[kecamatan][name]", location?.kecamatan?.toLowerCase());
      fdaddress.append("google[kabupaten][name]", location?.kabupaten?.toLowerCase());
      fdaddress.append("google[provinsi][name]", location?.provinsi?.toLowerCase());

      const address = await serviceGetIdAddressForProduct(fdaddress);

      const fdproducts = new FormData();
      fdproducts.append(`regional[${address?.data?.level}][id]`, address?.data?.id);

      const response = await serviceGetProductsByLocation(fdproducts);

      if (response.status === "failed" || response.status === "error") {
        toast.error(response.message);
        setLoading(false);

        return;
      }

      const categoryUrl = response.data.support.base_url["product-category"].original;
      const productUrl = response.data.support.base_url.product.original;
      const shopUrl = response.data.support.base_url.shop.original;

      const productArround = response.data.product_by_arround_you.map((item: any) => {
        const product = { ...item };

        product.photo = `${productUrl}${item.photo}`;
        product.information = JSON.parse(item.information);
        product.distance = handleAddDistance(item.shop_latitude, item.shop_longitude);

        return product;
      });

      const shopArround = response?.data?.shop_by_arround_you.map((item: any) => {
        const shop = { ...item };

        shop.logo = `${shopUrl}${item.logo}`;
        shop.distance = handleAddDistance(item.latitude, item.longitude);

        return shop;
      });

      const productCategory = response?.data?.top_category.map((item: any) => {
        const category = { ...item };

        category.logo = `${categoryUrl}${item.logo}`;

        return category;
      });

      const assetUri = JSON.stringify(response?.data?.support?.base_url);

      localStorage.setItem("assets", encoded(assetUri));

      setProducts(productArround);
      setShops(shopArround);
      setCategories(productCategory);
      setLastOrders(response?.data?.product_last_order);
      setLoading(false);
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        setLoading(false);

        return;
      }

      toast.error("Something went wrong while fetching home data");
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (!_.isEmpty(location)) {
      getAddressAndProducts();
    }
  }, [location]);

  return (
    <Layout doSetLocation={handleSetLocation}>
      {/* SEO */}
      <Head>
        <title>Foodizmart - Find Food, Serve Guest</title>
        <meta property="title" content="Foodizmart - Find Food, Serve Guest" />
        <meta property="type" content="website" />
        <meta property="url" content={`${APP_BASEURL}`} />
        <meta property="quote" content="Foodizmart - Temukan hidangan tepat untuk acaramu" />
        <meta name="description" content="Temukan hidangan tepat untuk acaramu." />
        <meta property="og:url" content={`${APP_BASEURL}`} />
        <meta property="og:site_name" content="Foodizmart" />
        <meta property="og:description" content="Temukan hidangan tepat untuk acaramu." />
        <meta property="og:locale" content="id_ID" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Foodizmart - Find Food, Serve Guest" />
        <meta property="og:quote" content="Foodizmart - Temukan hidangan tepat untuk acaramu" />
        <meta property="og:hashtag" content="#Foodizmart #PreOrder" />
      </Head>

      {/* Content */}
      <section className="py-4 osahan-main-body">
        <div className="osahan-home-page">
          <div className="osahan-body">

            <CategoryHome data={categories} isLoading={loading} />

            <SliderHome asseturi={asseturi.slider} />

            <ProductHome data={products} isLoading={loading} />

            <ShopHome data={shops} isLoading={loading} />

          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
